import { NavLink } from "react-router-dom";
import "./Navbar.css";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Logo from "../../assests/logo.png";
import { useState } from "react";

const Navbar = () => {
  const [isShowIcn, setIsShowIcn] = useState(false);
  return (
    <div className="nav-container">
      <div className="nav-block">
        <div>
          <NavLink className="nav-logo" to="/">
            <img src={Logo} width={70} height={70} />
          </NavLink>
        </div>
        <div className="nav-top">
          <ul>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/analysis"
              >
                Analysis
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/courses"
              >
                Courses
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/resources"
              >
                Resources
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/indicator"
              >
                Indicator
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
      <div className="sideNav_hide">
        <div className={isShowIcn ? "nav-sidebar-open" : "nav-sidebar"}>
          <ul>
            <li>
              <div
                className="close_icn"
                onClick={() => setIsShowIcn(!isShowIcn)}
              >
                <CloseIcon sx={{ fontSize: 25 }} />
              </div>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/"
              >
                Home
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/about"
              >
                About
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/analysis"
              >
                Analysis
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/cources"
              >
                Courses
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/resources"
              >
                Resources
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/indicator"
              >
                Indicator
              </NavLink>
            </li>
            <li className="hover-li">
              <NavLink
                onClick={() => setIsShowIcn(false)}
                className={({ isActive }) =>
                  isActive ? "nav-text-active" : "nav-text"
                }
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="menu_icn" onClick={() => setIsShowIcn(!isShowIcn)}>
          <MenuIcon sx={{ fontSize: 30 }} />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
