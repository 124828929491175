import "./contacts.css";
import Image from "../../assests/Astrology.jpg";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/misc/Footer/footer";

const Contact = () => {
  return (
    <div className="contact_container">
      <Navbar />
      <div style={{ marginTop: "70px" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
