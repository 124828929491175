import "../Button/button.css";
const Button = (props: any) => {
  const { title, buttonStyle, fontStyle, onButtonClick } = props;
  return (
    <div
      onClick={onButtonClick}
      className="button_container"
      style={buttonStyle}
    >
      <p style={fontStyle} className="title">
        {title}
      </p>
    </div>
  );
};
export default Button;
