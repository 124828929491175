import { Instagram, Youtube, X, Telegram, Facebook } from "../../../assests";
import "../Footer/footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="contact_title">
        <p>Stay in Touch</p>
      </div>
      <div className="contact_block">
        <div className="contact_block1">
          <div className="contact_div">
            <p className="subtitle_contact">Contact</p>
            <p className="val_contact">timetotrade.time@gmail.com</p>
          </div>
          <div className="contact_div">
            <p className="subtitle_contact">Location</p>
            <p className="val_contact">Somewhere on Earth</p>
          </div>
        </div>
        <div className="contact_block2">
          <p
            style={{
              width: "100%",
              fontSize: "20px",
              color: "white",
              marginBottom: "30px",
              marginInlineStart: "15px",
            }}
          >
            Connect With Us
          </p>
          <div className="social_block">
            <div
              onClick={() => {
                window.open("https://www.youtube.com/@timetotrade_2", "_blank");
              }}
            >
              <img src={Youtube} width={40} height={40} />
            </div>
            <div
              onClick={() => {
                window.open(
                  "https://www.instagram.com/timetotrade_2",
                  "_blank"
                );
              }}
            >
              <img src={Instagram} width={40} height={40} />
            </div>
            <div
              onClick={() => {
                window.open("https://x.com/timetotrade_2", "_blank");
                // window.location.href = "https://x.com/timetotrade_2";
              }}
            >
              <img src={X} width={40} height={40} />
            </div>
            <div
              onClick={() => {
                window.open("https://t.me/timetotrade_2", "_blank");
              }}
            >
              <img src={Telegram} width={40} height={40} />
            </div>
            <div
              onClick={() => {
                window.open(
                  "www.facebook.com/profile.php?id=61556900066941",
                  "_blank"
                );
              }}
            >
              <img src={Facebook} width={40} height={40} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
