import "../Resources/resources.css";

import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/misc/Footer/footer";

function Resources() {
  return (
    <div className="resources_container">
      <Navbar />
      <div className="block">
        <p>Comming Soon</p>
      </div>
      ;
      <div style={{}}>
        <Footer />
      </div>
    </div>
  );
}

export default Resources;
