import "../Home/home.css";
import { bgVid } from "../../assests";

import BackgroundImage1 from "../../assests/Astrology.jpg";
import BackgroundImage2 from "../../assests/pexels.jpg";
import BackgroundImage3 from "../../assests/Esoteric.jpg";
import Footer from "../../Components/misc/Footer/footer";
import Navbar from "../../Components/Navbar/Navbar";
import Button from "../../Components/Button/button";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const data = [
    {
      title: "Cources",
      description:
        "I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.",
      buttonName: "More Info",
    },
    {
      title: "Indicators",
      description:
        "I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.",
      buttonName: "More Info",
    },
    {
      title: "Setup",
      description:
        "I'm a paragraph. Click here to add your own text and edit me. It’s easy. Just click “Edit Text” or double click me to add your own content and make changes to the font. I’m a great place for you to tell a story and let your users know a little more about you.",
      buttonName: "More Info",
    },
  ];
  const myStyle = {
    backgroundImage: `url(${BackgroundImage1})`,
    height: "auto",
    Width: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center center",
    display: "flex",
  };

  return (
    <div className="home_container">
      <Navbar />

      <video
        style={{ filter: `blur(2px)` }}
        src={bgVid}
        width="100%"
        height={"100%"}
        autoPlay
        loop
        preload="auto"
        itemType="video/mp4"
      />
      <div className="slogan">
        <p className="slogan_big">TIME TO TRADE</p>
        <p className="slogan_small">Stars Align, Wealth Follows</p>
        <Button
          title="Subscribe"
          buttonStyle={{ paddingInline: "20px" }}
          onButtonClick={() => {
            navigate("/courses");
          }}
        />
      </div>
      {/* <div className="recent_analysis">
        <p className="recent_analysis_title">Recent Analysis</p>
      </div> */}

      <div style={myStyle}>
        <div className="card_container">
          {data.map((item) => (
            <div className="cardStyle">
              <p className="cardStyle_title">{item.title}</p>
              <p className="description_text">{item.description}</p>
              <div style={{ marginTop: "30%" }}>
                <Button
                  title={item.buttonName}
                  buttonStyle={{
                    height: "10px",
                    paddingInline: "80px",
                    backgroundColor: "black",
                  }}
                  fontStyle={{
                    fontWeight: "300",
                    fontSize: "18px",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div style={{ ...myStyle, backgroundImage: `url(${BackgroundImage2})` }}>
        <div className="card">
          <p className="title_16">ABOUT ME</p>
          <p style={{ width: "700px" }} className="description_text">
            I’m Priyank, a passionate financial trader with a unique approach to
            understanding the markets. Combining years of experience in finance
            with the ancient wisdom of astrology. My goal is to decode the
            hidden patterns in the stars and translate them into practical,
            actionable insights for success. Whether you're looking to make
            better investment decisions, manage your wealth, or understand how
            cosmic cycles impact the economy, you're in the right place. Join me
            as we explore the powerful connection between the financial world
            and the universe. Let’s unlock the potential of your financial
            future—one astrological sign at a time.
          </p>
          <Button
            title="Learn More"
            buttonStyle={{
              height: "10px",
              paddingInline: "80px",
              backgroundColor: "transparent",
              borderWidth: "2px",
              borderColor: "black",
              borderStyle: "solid",
            }}
            fontStyle={{
              fontWeight: "300",
              fontSize: "18px",
              color: "black",
              FontWeight: "600",
            }}
          />
        </div>
      </div>
      <div
        style={{
          ...myStyle,
          backgroundImage: `url(${BackgroundImage3})`,
        }}
      >
        <div className="card">
          <p className="title_16">Follow</p>
          <p className="description_text">
            Welcome visitors to your site with a short, engaging introduction.
            Double click to edit and add your own text.
          </p>
          <Button
            title="Start Now"
            buttonStyle={{
              height: "10px",
              paddingInline: "80px",
              backgroundColor: "black",
            }}
            fontStyle={{
              fontWeight: "300",
              fontSize: "18px",
            }}
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
