import YouTube from "react-youtube";
import "./vlogCard.css";

const VlogCard = (props: any) => {
  const { videoId } = props;

  console.log("Videoid ", videoId);

  const opts = {
    height: "300",
    width: "100%",
    position: "absolute",
    playerVars: {
      autoplay: 0,
      preload: true,
    },
  };
  return (
    <div className="container">
      <div className="video_container">
        <div className="video">
          <YouTube videoId={videoId} opts={opts} />
        </div>
        <div className="description">
          I’m writing to follow up on the invoice [#Invoice Number] issued on
          [Invoice Date] for the amount of [Invoice Amount]. According to our
          records, the payment has not yet been received, and the due date was
          [Due Date].
        </div>
        <div className="author_container">
          <p className="author_title">Created By:</p>
          <p className="author_name">Priyank</p>
        </div>
      </div>
    </div>
  );
};
export default VlogCard;
