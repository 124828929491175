import Footer from "../../Components/misc/Footer/footer";
import Navbar from "../../Components/Navbar/Navbar";
import "../About/about.css";
const About = () => {
  return (
    <div className="container">
      <Navbar />
      <Footer />
    </div>
  );
};

export default About;
