import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { Outlet } from "react-router-dom";
import bgVid from "../src/assests/back_vid.mp4";

function App() {
  return (
    <div className="app-style">
      <Outlet />
      {/* <div className="navbar">
        <Navbar />
      </div> */}
    </div>
  );
}

export default App;
