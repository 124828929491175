import { useState } from "react";
import "./tabView.css";
import VlogCard from "../VlogCard/vlogCard";

const TabView = () => {
  const tabData = [
    {
      title: "Technical Analysis",
      videoid: [
        "lRJHkQ6kFpk",
        "lSbzZaB37Vk",
        "lRJHkQ6kFpk",
        "lRJHkQ6kFpk",
        "lRJHkQ6kFpk",
      ],
    },
    {
      title: "Financial Astro ",
      videoid: ["lSbzZaB37Vk", "lSbzZaB37Vk", "lRJHkQ6kFpk", "lRJHkQ6kFpk"],
    },
  ];
  const tabClass = {
    width: "100%",
    border: "1px solid  rgb(12, 60, 96)",
    borderBottom: "none",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    backgroundColor: "rgb(12,60,96)",
    color: "white",
  };
  const [selectedTab, setSelectedTab] = useState(0);
  const selectTab = (index: any) => {
    setSelectedTab(index);
  };

  return (
    <div className="container1">
      <div style={{ cursor: "pointer" }} className="tab_container">
        {tabData.map((item, index) => (
          <div
            style={{
              width: "100%",
            }}
          >
            <div onClick={() => selectTab(index)}>
              <p style={selectedTab == index ? tabClass : { width: "100%" }}>
                {item.title}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div className="tab_content">
        {tabData.map((item, id) => (
          <div
            className={selectedTab === id ? "vlog_container_active" : "hidden"}
          >
            {item.videoid.map((item, index) => (
              <div className="item" key={index}>
                <VlogCard videoId={item} />
              </div>
            ))}
          </div>
        ))}
        {/* {tabData.map((item, id) =>
          selectedTab === id ? (
            <div className="vlog_container">
              {item.videoid.map((item, index) => (
                <div className="item" key={index}>
                  <VlogCard videoId={item} />
                </div>
              ))}
            </div>
          ) : null
        )} */}
      </div>
    </div>
  );
};
export default TabView;
