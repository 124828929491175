import "./indicator.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/misc/Footer/footer";
import { useRef } from "react";

function Indicator() {
  const footerRef = useRef<HTMLDivElement>(null);
  const scrollToFooter = () => {
    if (footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: "smooth" }); // Smooth scroll to footer
    }
  };
  return (
    <div className="indicator_container">
      <Navbar />
      <div className="block">
        <p>Comming Soon</p>
      </div>
      <p className="quote">This is an example of a quote.</p>
      <div ref={footerRef}>
        <Footer />
      </div>
    </div>
  );
}

export default Indicator;
