import "./courses.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/misc/Footer/footer";
import Button from "../../Components/Button/button";

function Courses() {
  const coursesData = [
    {
      title: "Course 1",
      price: "100",
      description: "Here description of Cource will be shown.",
      buttonName: "Select",
      features: [
        "1 Goal setting consultation",
        "3 Individual sessions",
        "Online resources",
      ],
    },
    {
      title: "Course 2",
      price: "150",
      description: "Here description of Cource will be shown.",
      buttonName: "Select",
      features: [
        "1 Goal setting consultation",
        "3 Individual sessions",
        "Online resources",
        "3 Individual sessions",
        "Online resources",
      ],
    },
  ];
  return (
    // <div className="course_container">
    //   <Navbar onContactClick={{}} />
    //   <div className="title_container">
    //     <p>Choose Plan</p>
    //   </div>
    //   <div className="courses_container">
    //     {coursesData.map((item) => (
    //       <div className="price_card">
    //         <p className="price_card_title">{item.title}</p>
    //         <div
    //           style={{
    //             display: "flex",
    //           }}
    //         >
    //           <p className="price_card_rupee_symbol">₹</p>
    //           <p className="price_card_amount">{item.price}</p>
    //         </div>
    //         <p className="price_card_description">{item.description}</p>

    //         <div style={{ marginTop: "20px" }}>
    //           <Button
    //             title="Select"
    //             buttonStyle={{
    //               width: "200px",
    //               height: "10px",
    //               backgroundColor: "black",
    //             }}
    //             fontStyle={{ fontWeight: "400", fontSize: "16px" }}
    //           />
    //         </div>
    //         <div className="lineBreak"></div>
    //         {item.features.map((item) => (
    //           <p className="courses_features">{item}</p>
    //         ))}
    //       </div>
    //     ))}
    //   </div>
    //   <Footer />
    // </div>
    <div className="indicator_container">
      <Navbar />
      <div className="block">
        <p>Comming Soon</p>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Courses;
