import "./analysis.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/misc/Footer/footer";
import TabView from "../../Components/TabView/tabView";

function Analysis() {
  return (
    <div className="container">
      <Navbar />
      <div style={{ marginTop: "20px" }}>
        <TabView />
      </div>
      <Footer />
    </div>
  );
}

export default Analysis;
